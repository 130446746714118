<template>
  <div class="production_wrap">
    <div class = "box">
      <div class="about-title">城市更新分院简介</div>
      <div class = "block-line"></div>
      <div class="about-container">
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">城市更新分院依托公司强大的技术实力和广阔的业务市场，凭借城市设计、城市更新、风貌保护、城中村改造等方面技术优势，将国土空间规划和各类设计咨询紧密结合，分院现已成为大连市具备核心竞争力的专业规划团队。
          </span> 
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">分院现有员工13人，其中8人具有硕士研究生及以上学历，2人拥有正高级职称， 6人拥有高级职称，7人具备国家注册规划师资格证书，1人具备国家一级注册建筑师资格证书，团队技术理念先进、职业经验丰富。
          </span> 
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">随着城市发展进入存量时代，城市更新必然成为城市高质量发展的重要路径。城市更新分院将继续发挥技术实力，把传承地区文化基因和提升人居环境品质作为未来重要规划方向，深耕细作、研精毕智，助力城市擦亮底色、焕发活力。
          </span> 
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">主要业务涵盖：
          </span> 
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">1.城市设计、城市更新、城中村改造等各类风貌设计、整治提升、拆除新建项目设计及咨询；
          </span> 
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">2.总体规划、详细规划和相关专项规划等国土空间规划项目；
          </span> 
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">3.历史街区、工业遗产、历史建筑等历史遗产保护利用规划；
          </span> 
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">4.选址论证报告、成片开发、保障性住房建设等各类设计及咨询项目；
          </span> 
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">5.建筑方案设计、环境景观设计等方案设计；
          </span> 
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">6.相关政策法规、技术规范等技术体系内容研究。
          </span> 
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">主要优秀成果：
          </span> 
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">《大连市总体城市设计》、《大连市城市更新专项规划》、《大连市钻石海湾地区城市设计》、《大连市城市设计法定化内容体系研究》、《大连市旅顺口区国土空间总体规划》、《大连市宗教活动场所专项规划》等。
          </span> 
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;"><b>院长：单学军</b></span> 
        </p>
        <div style="display: inline-block;text-align:center;width: 100%;height: 350px;">
          <img style="max-width: 100%; max-height: calc(100% - 50px);" src="../../assets/htmlimg/“城市设计所1”大.jpeg" alt="" />
          <div style="padding: 5px 0 10px;font-size: 12px;font-weight: bold;">城市设计所</div> 
        </div>
      </div>
    </div>
    <CallMe />
    <MobileCallme />
  </div>
</template>

<script>
import CallMe from '@/components/callme/index'
import MobileCallme from '@/components/callme/MobileCallme.vue'
export default {
  data() {
    return {
    }
  },
  components: {
    CallMe,
    MobileCallme,
  },
}
</script>

<style lang="scss" scoped>
.production_wrap {
  padding-top: 200px;
  width: 100%;
}
</style>